import * as React from "react";
import Header from "../../Components/Header/Header";
import OurNumbers from "../../Components/OurNumbers/OurNumbers";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const INostriNumeriPage = () => {
  return (
    <>
      <Seo locale="en-US" page="our figures" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <OurNumbers lang="en-US" />
      <Partnership lang="en-US" />
      <Footer lang="en-US" />
    </>
  );
};

export default INostriNumeriPage;
